@import '~antd/dist/antd.less';

@app_header_height: 60px;
@app_header-bg-color: #f5f5f5;
@app-side-bar-width: 220px;
@app-side-bar-collapsed-width: 80px;

html, body, #root, .app {
  min-width: 1366px;
  width: 100%;
  height: 100%;
}
.app-container {
  display: flex;
  width: 100%;
  height: 100%;
}
.app-main {
  width: 100%;
  height: 100%;
}
.app-side-bar {
  flex-shrink: 0;
  width: @app-side-bar-width;
  height: 100%;
}
.app-side-bar-collapsed {
  flex-shrink: 0;
  width: @app-side-bar-collapsed-width;
  height: 100%;
}
.app-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: @app-side-bar-width;
  height: @app_header_height;
  background: @app_header-bg-color;
  text-align: center;
}
.app-icon-collapsed {
  display: flex;
  justify-content: center;
  align-items: center;
  width: @app-side-bar-collapsed-width;
  height: @app_header_height;
  background: @app_header-bg-color;
  text-align: center;
}
.app-menu {
  width: 100%;
  min-height: calc(100% - @app_header_height);
}
.app-header {
  display: flex;
  align-items: center;
  width: 100%;
  height: @app_header_height;
  background: @app_header-bg-color;
}
.app-body {
  width: 100%;
  height: calc(100% - @app_header_height);
  overflow: auto;
}

