@app_header_height: 60px;

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  box-sizing: border-box;
}

.left {
  width: 60%;
  display: flex;
  align-items: center;
}

.share {
  height: @app_header_height;
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;

  .hot {
    width: 20px;
    height: 24px;
    margin-right: 10px;
  }

  .arrow {
    width: 16px;
    height: 16px;
    margin-left: 8px;
  }

  &-toast {
    display: none;
    width: 160px;
    height: 230px;
    padding: 24px 20px 24px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 12px 0 rgba(18, 68, 142, 0.08);
    margin: auto;
    z-index: 100;
    position: absolute;
    right: 0;
    left: 0;
    top: @app_header_height;

    .title {
      color: #000;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
    }

    .qr {
      margin-top: 10px;
      width: 120px;
      height: 120px;
      padding: 4px;
      border-radius: 8px;
      border: 1px solid #EEE;
      display: flex;
      justify-content: center;
      align-items: center;

      .code {
        width: 112px;
        height: 112px;
      }
    }

    .subtitle {
      margin-top: 10px;
      color: #666;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }
  }


  &:hover .share-toast {
    display: block;
  }
}





