.container {
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100px;

  > div:first-child {
    display: flex;
    align-items: center;
  }

  .line {
    width: 2px;
    height: 40px;
    margin: 0 40px;
    background-color: #E0E0E0;
  }

  .title {
    font-size: 24px;
    font-family: PingFangHK-Medium, PingFangHK;
    font-weight: 500;
    color: #3E4044;
    line-height: 32px;
  }
}

.body {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: calc(100% - 160px);
  background-size: cover;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  font-size: 14px;
  font-family: PingFangHK-Regular, PingFangHK;
  font-weight: 400;
  color: #999;
  line-height: 20px;
}

.login-box {
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 420px;
  margin-right: 14%;
  background: #fff;

  &-title {
    margin: 24px 30px 14px 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e8e8e8;
    font-size: 28px;
    font-family: PingFangHK-Medium, PingFangHK;
    font-weight: 500;
    color: #333;
    line-height: 40px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20%;
  }
}

.server {
  &-label {
    font-size: 24px;
    font-family: PingFangHK-Medium, PingFangHK;
    font-weight: 500;
    color: #3e4044;
    line-height: 32px;
  }

  &-value {
    margin-left: 10px;
    font-size: 24px;
    font-family: PingFangHK-Medium, PingFangHK;
    font-weight: 500;
    color: #2C81FF;
    line-height: 32px;
  }
}

.wechat {
  cursor: pointer;

  &-text {
    margin-top: 18px;
    font-size: 18px;
    font-family: PingFangHK-Medium, PingFangHK;
    font-weight: 500;
    color: #666;
    line-height: 26px;
  }
}

.agreement {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.radio {
  margin-right: 10px;
}

.doc-link {
  color: #2c81ff;
  cursor: pointer;
}

